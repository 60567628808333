import Repository from "@/repositories/api.js"
const resource = "/admin/videos"

export default {
  async get(videoID) {
    let response = await Repository.get(`${resource}/${videoID}`)
    return response.data.video
  },
  async create(videoData) {
    const formData = new FormData()
    const entries = Object.entries(videoData)
    for (const [key, value] of entries) {
      formData.append(`video[${key}]`, value ? value : "")
    }
    let response = await Repository.post(`${resource}`, formData)
    return response.data
  },
  async update(videoID, videoData) {
    const formData = new FormData()
    const entries = Object.entries(videoData)
    for (const [key, value] of entries) {
      formData.append(`video[${key}]`, value ? value : "")
    }
    let response = await Repository.patch(`${resource}/${videoID}`, formData)
    return response.data
  },
  async destroy(videoID) {
    let response = await Repository.delete(`${resource}/${videoID}`)
    return response.data
  },
  async sort(videos) {
    let response = await Repository.patch(`${resource}/sort`, {
      videos: videos
    })
    return response.data
  }
}
