<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Video bearbeiten">
      <video-form
        :loading="loading"
        :video="video"
        :form-submitted="saveVideo"
        mode="edit"
      ></video-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import VideoForm from "@/components/forms/VideoForm"
import VideosRepository from "@/repositories/videos_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Video bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    VideoForm
  },
  mixins: [ApiErrorHandlerMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      loading: true,
      video: {},
      prevRoute: null
    }
  },
  async created() {
    try {
      this.video = await VideosRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveVideo(video) {
      await VideosRepository.update(video.id, video)
      if (this.prevRoute) {
        this.$router.push(this.prevRoute)
      } else {
        this.$router.push({ name: "VideoCategoriesPage" })
      }
    }
  }
}
</script>
