<template>
  <base-form
    v-slot="slotProps"
    :model="video"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Video"
  >
    <div v-if="persisted">
      <el-form-item label="Name" prop="name">
        <el-input v-model="slotProps.model.name" v-focus></el-input>
      </el-form-item>

      <image-upload-field
        current-label="Aktuelles Vorschaubild"
        label="Vorschaubild auswählen"
        :current-image="slotProps.model.thumbnail_url"
        allowed-file-types="image/jpeg, image/png"
        @file-chosen="setFile"
      />
    </div>
    <div v-else>
      <el-form-item
        label="Youtube Video URL (Es funktionieren nur Youtube Videos, keine anderen Video URLs)"
        prop="youtubeURL"
      >
        <el-input
          v-model="slotProps.model.youtubeURL"
          v-focus
          placeholder="z.B. https://www.youtube.com/watch?v=LBmcoS9szSo"
        ></el-input>
      </el-form-item>
    </div>
  </base-form>
</template>

<script>
import BaseForm from "@/components/forms/BaseForm"
import ImageUploadField from "@/components/ImageUploadField"

export default {
  components: {
    BaseForm,
    ImageUploadField
  },
  props: {
    video: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    youtubeLink: {
      get: function() {
        return this.video.youtubeURL
      },
      set: function(value) {
        this.video.youtubeURL = value
        this.video.youtube_video_id = this.YouTubeGetID(value)
      }
    },
    persisted() {
      return this.video.hasOwnProperty("id")
    },
    rules() {
      return {
        name: [
          {
            required: this.persisted,
            message: "Bitte Namen angeben",
            trigger: "blur"
          }
        ],
        youtubeURL: [
          {
            required: true,
            message: "Bitte Youtube Video URL angeben",
            trigger: "blur"
          },
          { validator: this.validateYoutubeURL, trigger: "change" }
        ]
      }
    }
  },
  watch: {
    video: {
      handler: function(val) {
        if (val.youtubeURL) {
          this.video.youtube_video_id = this.YouTubeGetID(val.youtubeURL)
        }
      },
      deep: true
    }
  },
  created() {},
  methods: {
    setFile(file) {
      this.video.thumbnail = file
    },
    YouTubeGetID(url) {
      var ID = ""
      url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i)
        ID = ID[0]
      } else {
        ID = url
      }
      return ID
    },
    validateYoutubeURL(rule, value, callback) {
      if (
        value &&
        !value.includes("youtube.com") &&
        !value.includes("youtu.be") &&
        !value.includes("youtube-nocookie.com")
      ) {
        callback(
          new Error(
            "Bitte vollständige und gültige Youtube Video URL angeben. z.B. https://www.youtube.com/watch?v=3PRUeSlVxiU"
          )
        )
      } else {
        callback()
      }
    }
  }
}
</script>
